import React, {useEffect, useRef} from "react";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import MainPage from "./MainPage";
import {BrowserRouter, HashRouter, Link, Route, Routes} from "react-router-dom";
import FontFaceObserver from "fontfaceobserver";
import Privacy from "./Privacy";
import Terms from "./Terms";
import Support from "./Support";
import Zoom from "./Zoom";
import {CookieConsent, getCookieConsentValue} from "react-cookie-consent";

const regularFont = new FontFaceObserver("CabinetGrotesk", { weight: 400 });
const boldFont = new FontFaceObserver("CabinetGrotesk", { weight: 700 });

Promise.all([regularFont.load(), boldFont.load()])
    .then(() => {
        document.documentElement.classList.add("fonts-loaded");
    })
    .catch((error) => {
        console.error("Font failed to load:", error);
    });

function App() {
    // Create a reference to pass to the IncreasesSales component
    const targetRef = useRef(null);

    // Function to handle scrolling to the target div
    const scrollToChildDiv = () => {
        console.log("asdasd")
        if (targetRef.current) {
            const offset = 94; // Set the offset value
            const topPosition = targetRef.current.getBoundingClientRect().top + window.scrollY - offset;

            window.scrollTo({
                top: topPosition,
                behavior: "smooth",
            });
        }
    };

    const initApollo = () => {
        const n = Math.random().toString(36).substring(7);
        const script = document.createElement('script');
        script.src = `https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache=${n}`;
        script.async = true;
        script.defer = true;
        script.onload = () => {
            window.trackingFunctions.onLoad({ appId: '66b9c6480899ad02d1475a78' });
        };
        document.head.appendChild(script);
    };

    // Check cookie consent value on component mount
    useEffect(() => {
        if (getCookieConsentValue("garbaCookieConsent") === "true") {
            initApollo();
        }
    }, []);

    // Handle accept button click
    const handleAccept = () => {
        initApollo();
    };

    return (
        <HashRouter >
        <div className="App">
            <Navigation scrollToChildDiv={scrollToChildDiv} />
                <Routes>
                    <Route path="/" element={<MainPage targetRef={targetRef} />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/terms-of-use" element={<Terms />} />
                    <Route path="/support" element={<Support />} />
                    <Route path="/zoom-guide" element={<Zoom />} />
                </Routes>
            <Footer />
            <CookieConsent
                location="bottom"
                buttonText="Accept"
                declineButtonText={"Decline"}
                enableDeclineButton
                declineButtonStyle={{ background: "#fff",border:"1px solid #000", color:"#000", fontSize: "15px", borderRadius: 4, padding: "10px 20px"}}
                cookieName="garbaCookieConsent"
                style={{ background: "#fff", color:"#000", fontWeight: "bold", borderTop: "1px solid #000" }}
                buttonStyle={{ background: "#40eec0", color:"#000", fontSize: "15px", borderRadius: 4, padding: "10px 20px" }}
                expires={150}
                onAccept={handleAccept}
            >
                This website uses cookies to enhance the user experience.
            </CookieConsent>
        </div>
        </HashRouter>
    );
}

export default App;
